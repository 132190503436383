import React from "react";
import { useState } from "react";
import { post, ApiError } from 'aws-amplify/api';
import { useLocation } from 'react-router-dom';
import {
    TextField,
    Box,
    MenuItem,
    Paper,
  } from "@mui/material";

import "../styles/form.scss";
import "../styles/main.scss";
import Sidebar from "../components/sidebar";
import SubmitButton from '../components/submit_button';

import stateCodes from "../constants/addrStates";
import '../styles/input_form.scss'


const EditUserDetails = () => {

  const {state} = useLocation();
  const { userDetails } = state;
  const [addressState, setAddressState] = useState(userDetails?.state ?? "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState(userDetails.name);
  const [address1, setAddress1] = useState(userDetails.address1);
  const [birthdate, setBirthdate] = useState(userDetails.birthDate);
  const [city, setCity] = useState(userDetails.city);
  const [zip, setZip] = useState(userDetails.zip);
  const [eeCont, setEeCont] = useState(userDetails.ee_contribution);

  const whiteBg = { style: { background: "white" } };

  const submitTheForm = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const apiName = "users";
    const path = "/users/updateAppUser";
    const requestBody = {
      address1: address1,
      birthDate: birthdate,
      city: city,
      ee_contribution: eeCont,
      name: name,
      residenceState: addressState,
      zip: zip,
      pk: userDetails.pk,
      sk: userDetails.sk
    };
    const requestHeaders = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      const restOperation = post({
        apiName: apiName, 
        path: path, 
        options: {
          headers: requestHeaders,
          body: requestBody,
        }
      });
      await restOperation.response;
      alert("We updated the user!");
    } catch (e) {
      alert("ERROR!!!!! There was an error and we did not update the user. See console (F12) and contact justin");
      console.log('The error is below');
      console.log(e);
      if (e instanceof ApiError) {
        if (e.response) {
            const { 
            statusCode, 
            body 
            } = e.response;
            console.error(`Received ${statusCode} error response with payload: ${body}`);
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className="container">
      <Sidebar />
      <main>
        <h1>
          <center>Edit User Details</center>
        </h1>
        <hr />
        < br />
        <Paper
          sx={{backgroundColor: 'white', margin: '0px 40px'}}
        >
        <form onSubmit={submitTheForm} className="dataForm">
        <TextField
            fullWidth
            variant="outlined"
            label="Name"
            inputProps={whiteBg}
            name="fullName"
            id="fullName"
            autoComplete="off"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
        />
        <TextField
            fullWidth
            variant="outlined"
            label="Address Line 1"
            inputProps={whiteBg}
            name="address1"
            id="address1"
            autoComplete="off"
            required
            value={address1}
            onChange={(e) => setAddress1(e.target.value)}
        />
        <TextField
            fullWidth
            variant="outlined"
            label="Birthday"
            inputProps={whiteBg}
            name="birthDate"
            id="birthDate"
            autoComplete="off"
            required
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
        />

        <Box
            sx={{
                flexDirection: "row",
                display: "flex",
                marginTop: "15px",
                width: "inherit"
            }}
            flex={1}
            width="100vw"
        >
            <TextField
            label="City"
            variant="outlined"
            fullWidth
            name="city"
            id="city"
            required
            margin="none"
            autoComplete="off"
            inputProps={whiteBg}
            size="small"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            />

            <TextField
            select
            label="State"
            variant="outlined"
            name="addrState"
            id="addrState"
            required
            value={addressState}
            onChange={(e) => setAddressState(e.target.value)}
            inputProps={whiteBg}
            sx={{
                width: "150px",
                marginRight: "10px",
                marginLeft: "10px",
            }}
            >
            <MenuItem disabled value="">
                <em>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </em>
            </MenuItem>
            {stateCodes.map((name) => (
                <MenuItem key={name} value={name}>
                {name}
                </MenuItem>
            ))}
            </TextField>

            <TextField
            label="Zip"
            variant="outlined"
            name="zipCode"
            id="zipCode"
            required
            size="small"
            autoComplete="off"
            InputProps={{
                inputProps: {
                    minLength: 5,
                    maxLength: 5,
                    style: { background: "white"}
                },
            }}
            sx={{
                width: "150px",
                marginLeft: "50px",
            }}
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            />
        </Box>
        <TextField
            fullWidth
            variant="outlined"
            label="EE Contribution"
            name="eeContribution"
            id="eeContribution"
            autoComplete="off"
            type="number"
            InputProps={{
                inputProps: {
                    max: 99999.99,
                    min: 0.01,
                    step: 0.01,
                    style: { background: "white"}
                },
            }}
            value={eeCont}
            onChange={(e) => setEeCont(e.target.value)}
        />
        <TextField
            defaultValue={userDetails.accountBalance}
            fullWidth
            variant="outlined"
            label="Account Balance"
            inputProps={whiteBg}
            name="eeContribution"
            id="eeContribution"
            autoComplete="off"
            disabled
        />
        <TextField
            defaultValue={userDetails.pk}
            fullWidth
            variant="outlined"
            label="Plan"
            inputProps={whiteBg}
            name="plan"
            id="plan"
            autoComplete="off"
            disabled
        />
        <TextField
            defaultValue={userDetails.empCode}
            fullWidth
            variant="outlined"
            label="Company"
            inputProps={whiteBg}
            name="empCode"
            id="empCode"
            autoComplete="off"
            disabled
        />
        <TextField
            defaultValue={userDetails.addventureId}
            fullWidth
            variant="outlined"
            label="Addventure ID"
            inputProps={whiteBg}
            name="addventureId"
            id="addventureId"
            autoComplete="off"
            disabled
        />
        {<SubmitButton btnText="Submit" isLoading={isSubmitting} />}

        </form>
        <br />
        <br />
        </Paper>
        <br />
        <br />

      </main>
    </div>
  );
};

export default EditUserDetails;
