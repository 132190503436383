import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get, ApiError } from 'aws-amplify/api';
import Button from '@mui/material/Button';

import { useSelector, useDispatch } from "react-redux";

import "../styles/list_users.scss";
import "../styles/main.scss";
import Sidebar from "../components/sidebar";
import { fetchCompaniesFromAWS } from "../store/companies";

const ListUsers = () => {
  const store = useSelector((state) => state.companies);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [returnedUsers, setReturnedUsers] = useState([]);
  const navigate = useNavigate();
  const [sorting, setSorting] = useState({ field: 'name', ascending: false });

  function applySorting(key, ascending) {
    setSorting({ key: key, ascending: ascending });
  }

  useEffect(() => {
    // Copy array to prevent data mutation
    const currentUsersCopy = [...returnedUsers];

    // Apply sorting
    const sortedCurrentUsers = currentUsersCopy.sort((a, b) => {
      return a[sorting.key].localeCompare(b[sorting.key]);
    });

    // Replace currentUsers with sorted currentUsers
    setReturnedUsers(
      // Decide either currentUsers sorted by ascending or descending order
      sorting.ascending ? sortedCurrentUsers : sortedCurrentUsers.reverse()
    );
  }, [sorting]);

  function getFileHeader() {
    return 'EEID,Payroll Identifier,Plan Year,Payroll Contribution Amount';
  }
  function getSingleRow(data) {
    try {
      const planName = getCompanyPlanName();
      if(planName === null) {
        return null;
      }
      if(data.eeNo.includes('#')) {
        const eeNoAlone = data.eeNo.split('#').pop();
        return `${eeNoAlone},EE,${planName},${data.ee_contribution}`;
      }
      
      return `${data.eeNo},EE,${planName},${data.ee_contribution}`;
    } catch (e) {
      console.log('We hit an error!!!!');
      console.log(e);
      console.log('error is above, data incoming is below');
      console.log(data);
      console.log('This ends the catch block');
      return null
    }
  }

  function getFileBody() {
    let arrBody = [];
    for (let i = 0; i < returnedUsers.length; i++) {
      const row = getSingleRow(returnedUsers[i]);
      if(row === null) {
        console.log('bailing from getFileBody since null was returned.');
        return null;
      }
      if(returnedUsers[i].disabled !== true) {
        arrBody.push(row);
      }
    }
    return arrBody;
  }

  function getCompanyPlanName() {
    const company = store.companies.filter((e) => e.sk === selectedCompany);
    if(company == null || company.length === 0) {
      alert('There was an error in getting the company plan name. Please look at console (F12) and contact justin');
      console.log('The companies are below');
      console.log(store.companies);
      console.log(`The selected company is: ${selectedCompany}`);
      return null;
    }
    return company[0].wexMoneyPlanName;
  }

  function getFileContents() {
    const header = getFileHeader();
    const body = getFileBody();
    if(body == null) {
      console.log('bailing from getFileContents because body is null');
      return null;
    }
    
    var fileContents =  `${header}\r\n`;
    for(let j = 0; j < body.length; j++) {
      fileContents += `${body[j]}\r\n`;
    }
    return fileContents;
  }

  async function downloadFile() {
    const contents = getFileContents();
    if(contents == null) {
      console.log('not downloading file because contents is null. aborting');
      return;
    }
    const element = document.createElement("a");
    const file = new Blob([contents], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "Contribution.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  async function getUsers(comp) {
    if (comp == null || comp.length === 0) {
      return;
    }
    const apiName = "users";
    const path = "/users";
    try {
      const restOperation = get({ 
        apiName: apiName,
        path: path,
        options: {
          headers: {'Access-Control-Allow-Origin': 'Authorization, Access-Control-Allow-Origin,Access-Control-Allow-Headers'},
          queryParams: {company: comp}
        }
      });
      const { body } = await restOperation.response;
      const json = await body.json();
      return json;
    } catch (e) {
      console.log('GET call failed.');
      console.log(e);
      if (e instanceof ApiError) {
        if (e.response) {
            const { 
            statusCode, 
            body 
            } = e.response;
            console.error(`Received ${statusCode} error response with payload: ${body}`);
        }
      }
    }
  }

  useEffect(() => {
      getUsers(selectedCompany).then((users) => {
        if(users != null) {
          setReturnedUsers(JSON.parse(users));
        }
      });
    }, [selectedCompany]);

  const comp = useSelector((state) => state.companies);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompaniesFromAWS(comp.companies));
  }, [dispatch, comp.companies]);

  const companyChanged = (val) => {
    if (val.target.value != null && val.target.value.length > 0) {
      setSelectedCompany(val.target.value);
    }
  };

  const Select = ({ label, id, children, ...rest }) => (
    <div>
      <label htmlFor={id}>{label}</label>
      <select id={id} {...rest} onChange={companyChanged}>
        {children}
      </select>
    </div>
  );

  return (
    <div className="container">
      <Sidebar />
      <main>
        <div className="topRow">
          <Select label="" id="company" name="company" required>
            <option value="">Select a company...</option>
            {comp.companies &&
              comp.companies.map((x, y) => <option key={x.sk}>{x.sk}</option>)}
          </Select>
        </div>
        <hr />
        <div>
          {!selectedCompany && (
            <h1 className="centerText">Select a company above...</h1>
          )}
          {selectedCompany && !returnedUsers && (
            <h1 className="centerText">Fetching users...</h1>
          )}
          {returnedUsers && returnedUsers.length > 0 && (
            <div>
              <h1>
                <center>{selectedCompany}</center>
              </h1>
              <h3>
                <center>Click on a row to edit user</center>
              </h3>
                <Button 
                  variant="contained" 
                  color="primary"
                  sx={{
                    float: 'right',
                    marginRight: '42px',
                    marginBottom: '10px',
                    borderRadius: 4,
                  }}
                  onClick={() => {
                    downloadFile();
                }}>
                    Download
                </Button>
                <table className="styled-table">
                  <thead>
                    <tr key="header">
                      <th onClick={() => applySorting('name', !sorting.ascending)}>Name</th>
                      <th onClick={() => applySorting('contact_email', !sorting.ascending)}>Email</th>
                      <th><center>Balance</center></th>
                      <th><center>EE Contribution</center></th>
                      <th onClick={() => applySorting('eeNo', !sorting.ascending)}><center>EE ID</center></th>
                      <th onClick={() => applySorting('addventureId', !sorting.ascending)}><center>WEX ID</center></th>
                    </tr>
                  </thead>
                  <tbody>
                    {returnedUsers &&
                      returnedUsers.map((user) => {
                        // If user is disabled, show the disabled row
                        if (user.disabled) {
                          return (
                            <tr
                              key={user.sk}
                              onClick={() =>
                                navigate("/userDetails", {
                                  state: {
                                    uuid: user.sk.slice(4),
                                    company: selectedCompany,
                                  },
                                })
                              }
                            >
                              <td>{user.name}</td>
                              <td>{user.contact_email}</td>
                              <td colSpan="4"><center><b>**   **   **    DISABLED    **   **   **</b></center></td>
                            </tr>
                          );
                        } else {
                          // If user is not disabled, show regular user row
                          return (
                            <tr
                              key={user.sk}
                              onClick={() =>
                                navigate("/userDetails", {
                                  state: {
                                    uuid: user.sk.slice(4),
                                    company: selectedCompany,
                                  },
                                })
                              }
                            >
                              <td>{user.name}</td>
                              <td>{user.contact_email}</td>
                              <td>
                                <center>
                                  ${user.accountBalance.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                </center>
                              </td>
                              <td>
                                <center>
                                  ${parseFloat(user.ee_contribution ?? 0).toFixed(2)}
                                </center>
                              </td>
                              <td>
                                <center>{user.eeNo.split('#').pop()}</center>
                              </td>
                              <td>
                                <center>{user.addventureId}</center>
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </table>

              <br />
              <br />
              <br />
            </div>
          )}
          {returnedUsers && returnedUsers.length === 0 && (
            <div>
              <br /> <br />
              <center>
                There are no users yet for{" "}
                <b>{selectedCompany} or data is loading</b>.
              </center>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default ListUsers;
