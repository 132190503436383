import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { patch, ApiError } from 'aws-amplify/api';
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import "../styles/form.scss";
import "../styles/main.scss";
import Sidebar from "../components/sidebar";
import TextInputForm from "../components/text_input_form";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { FiCalendar } from "react-icons/fi";

async function addMoney(pk, sk, amount, description, selectedDate) {
  if(selectedDate == null) {
    alert('You must enter a date. Not adding money...');
    return;
  }
  const apiName = "users";
  const path = "/users/money";
  const requestBody = {
    data: [
      {
        pk: pk,
        sk: sk,
        amt: amount,
        desc: description,
        milliseconds: selectedDate.valueOf(),
      },
    ],
  };
  const requestHeaders = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  try {

    const restOperation = patch({
      apiName: apiName, 
      path: path, 
      options: {
        headers: requestHeaders,
        body: requestBody,
      }
    });

    await restOperation.response;
    //throws exception if not status code of 200 so we made it here we good
    console.log('Money was successfully added...');
    return true;
  } catch (e) {
    console.log(e);
    alert("We hit an error in addMoney. Check console (F12) and contact justin");
    console.log('Error is above');
    if (e instanceof ApiError) {
      if (e.response) {
          const { 
          statusCode, 
          body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
      }
    }
    return false;
  }
}

const AddMoneyTransaction = () => {
  const [isSending, setIsSending] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [selDate, setSelDate] = useState();
  const location = useLocation();
  const dynamo = location.state.dynamo[0];
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
    const StyledButton = styled(IconButton)(({ theme }) => ({
      //borderRadius: theme.shape.borderRadius,
      width: '40px',
      marginTop: '15px'
    }));
    const StyledDay = styled(PickersDay)(({ theme }) => ({
      borderRadius: theme.shape.borderRadius,

    }));

  async function submitHandler(values) {
    console.log(values);
    const desc = values.description;
    const amt = Number(values.amount);
    const pk = dynamo.pk;
    const sk = dynamo.sk;
    if (pk === null || pk === undefined) {
      console.log("Dynamo data is below. PK is empty somehow?");
      console.log(dynamo);
      alert(
        "pk is empty, which should never happen. Please look at console and contact Justin"
      );
      return;
    }
    if (sk === null || sk === undefined) {
      console.log("Dynamo data is below. SK is empty somehow?");
      console.log(dynamo);
      alert(
        "sk is empty, which should never happen. Please look at console and contact Justin"
      );
      return;
    }
    if (amt === 0) {
      alert("You cannot submit a $0 amount. Aborting...");
      return;
    }

    setIsSending(true);
    const wasGood = await addMoney(pk, sk, amt, desc, selDate);
    if (wasGood) {
      setResponseText(
        `SUCCESS! $${amt} was ${amt > 0 ? "added to" : "subtracted from"} ` +
          `${dynamo.name}'s account`
      );
    } else {
      setResponseText(
        `ERROR! The amount was not added. Please check the console and contact Justin.`
      );
    }
    setIsSending(false);
  }

  function limitDecimalPlaces(e, count) {
    if (e.target.value.indexOf(".") === -1) {
      return;
    }
    if (e.target.value.length - e.target.value.indexOf(".") > count) {
      e.target.value = parseFloat(e.target.value).toFixed(count);
    }
  }

  return (
    <div className="container">
      <Sidebar />
      <main>
        <h1
          onClick={() =>
            navigate("/userDetails", {
              state: {
                uuid: dynamo.sk.slice(4),
                company: dynamo.pk,
              },
            })
          }
        >
          <center>Add Money Transaction</center>
        </h1>
        <center
          onClick={() =>
            navigate("/userDetails", {
              state: {
                uuid: dynamo.sk.slice(4),
                company: dynamo.pk,
              },
            })
          }
        >
          <u>
            <b>{dynamo.name}</b>
          </u>{" "}
          from {dynamo.pk}
        </center>
        <form onSubmit={handleSubmit(submitHandler)}>
          <TextInputForm
            fieldName="amount"
            label="Amount"
            id="amount"
            register={register}
            error={errors.amount}
            onInput={(e) => limitDecimalPlaces(e, 2)}
          />
          <TextInputForm
            fieldName="description"
            label="Description"
            id="description"
            name="description"
            maxLength={70}
            error={errors.description}
            register={register}
          />
          <DatePicker 
            label="Date of Transaction"
            onChange={(d) => setSelDate(d)}
            slots={{
              openPickerIcon: FiCalendar,
              openPickerButton: StyledButton,
              day: StyledDay,
            }}
            slotProps={{
              actionBar: {
                actions: ['today', 'clear'],
              },
              calendarHeader: {
         sx: {
            position: "relative",
            '& .MuiPickersArrowSwitcher-root': {
                width: 0
             },
             "& .MuiPickersCalendarHeader-labelContainer": {
                margin: "auto"
             },
             "& .MuiIconButton-edgeEnd": {
                position: "absolute",
                left: 0, top: 0, bottom: 0,
                width: '20px',
                marginLeft: '10px'
             },
             "& .MuiIconButton-edgeStart": {
                position: "absolute",
                right: 0, top: 0, bottom: 0,
                width: '20px',
                marginRight: '10px'
            }
         }
      },
       leftArrowIcon: {
         sx: { color: "#000", fontSize: "2rem" }
      },
      rightArrowIcon: {
         sx: { color: "#000", fontSize: "2rem" }
      },
              openPickerIcon: { fontSize: 'large' },
              openPickerButton: { color: 'primary' },
              textField: {
                variant: 'filled',
                focused: true,
                color: 'primary',
                size: 'medium'
              },
              
            }}
              sx={{"& .MuiInputAdornment-root": {
            width: "40px"
          }, "& .MuiPickersArrowSwitcher-spacer": {width: "10px"} }}
            />

          {isSending === true && (
            <center>
              <CircularProgress />
            </center>
          )}
          {isSending === false && <input type="submit" />}
          {isSending === false && <input type="reset" />}
          <br />
          <br />
          {responseText && responseText.length > 0 && (
            <center>{responseText}</center>
          )}
          <br />
          <br />
        </form>
        <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
          <center>
            <b>NOTE:</b> You should only use this form to add (or remove) a
            single monetary transaction from a single user. This is needed/used
            because we cannot do real-time reconciliation with WEX. So the only
            time you should come here is if you get an email from the daily job
            that says an account with WEX and Addventure is out of sync.
            <br />
            <br />
            <b>Note 2:</b> This only updates the Addventure side, and will{" "}
            <u>never</u> update anything on the WEX side. The idea is to use
            this to keep us in sync, because what appears in the app is what is
            on the Addventure side.
          </center>
        </div>
      </main>
    </div>
  );
};

export default AddMoneyTransaction;
